import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import IconButton from '@material-ui/core/IconButton';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import HelpIcon from '@material-ui/icons/Help';
import { makeStyles, Theme, useTheme, createStyles } from '@material-ui/core/styles';
import MobileStepper from '@material-ui/core/MobileStepper';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import { Container } from "@material-ui/core";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import CloseIcon from '@material-ui/icons/Close';

const tutorialSteps = [
    {
        label: `Open the page in your mobile wallet's built-in browser or use your wallet's browser extension.`,
        imgPath:
            '/howto/11.png',
    },
    {
        label: `Step #1: Select "CONNECT WALLET".`,
        imgPath:
            '/howto/22.png',
    },
    {
        label: 'Step #2: Select your wallet.',
        imgPath:
            '/howto/33.png',
    },
    {
        label: `Step #3: Select "Connect".`,
        imgPath:
            '/howto/44.png',
    },
    {
        label: 'Step #4: Live!  Select "MINT".',
        imgPath:
            '/howto/55.png',
    },
    {
        label: 'Step #5: Select "Approve".',
        imgPath:
            '/howto/66.png',
    },
    {
        label: 'You just established an immutable record of authenticity and ownership!',
        imgPath:
            '/howto/77.png',
    },
];

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        groot: {
            flexGrow: 1,
        },
        header: {
            display: 'flex',
            alignItems: 'center',
            height: '100px',
            overflow: 'break-word',
            backgroundColor: theme.palette.background.default,
        },
        img: {
            height: '300px',
            width: 'auto',
            marginLeft: 'auto',
            marginRight: 'auto',
            display: 'block',
            userSelect: "none",
        },
        closeButton: {
            position: 'absolute',
            right: theme.spacing(1),
            top: theme.spacing(1),
            color: theme.palette.grey[500],
        },
    }),
);

export default function HelpDialog() {

    const [open, setOpen] = React.useState(false);
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleClickAway = () => {
        setOpen(false);
    };

    const classes = useStyles();
    const [activeStep, setActiveStep] = React.useState(0);
    const maxSteps = tutorialSteps.length;

    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    return (

        <ClickAwayListener onClickAway={handleClickAway}>
            <Container>
                <IconButton autoFocus size="medium" onClick={handleClickOpen} >
                    <HelpIcon fontSize="large" />
                </IconButton>
                <Dialog
                    fullScreen={fullScreen}
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="responsive-dialog-title"
                    style={{
                        marginTop: "auto",
                        marginBottom: "auto",
                        marginLeft: "auto",
                        marginRight: "auto",
                    }}
                >
                    <IconButton
                        aria-label="close"
                        autoFocus
                        onClick={handleClose}
                        style={{
                            position: 'absolute',
                            right: theme.spacing(1),
                            top: theme.spacing(1),
                            color: theme.palette.grey[500],
                        }}
                    >
                        <CloseIcon />
                    </IconButton>
                    <DialogTitle id="responsive-dialog-title">{"How do I mint?"}</DialogTitle>
                    <DialogContent style={{ overflowY: "hidden" }}>
                        <Paper
                            style={{
                                paddingTop: 5,
                                paddingLeft: 0,
                                paddingRight: 0,
                                paddingBottom: 5,
                                backgroundColor: "#151A1F",
                                borderRadius: 0,
                            }}
                        >
                            <Container maxWidth="sm" style={{ width: '100%', marginBottom: 0, position: "relative", }} >
                                <div className={classes.groot}>
                                    <MobileStepper
                                        steps={maxSteps}
                                        position="static"
                                        style={{ height: "auto", marginLeft: 0, marginRight: 0, marginTop: 0 }}
                                        activeStep={activeStep}
                                        nextButton={
                                            <Button size="large" autoFocus onClick={handleNext} disabled={activeStep === maxSteps - 1}>
                                                Next
                                                {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
                                            </Button>
                                        }
                                        backButton={
                                            <Button size="large" autoFocus onClick={handleBack} disabled={activeStep === 0}>
                                                {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
                                                Back
                                            </Button>
                                        }
                                    />
                                    <Paper square className={classes.header} style={{ paddingLeft: 10, paddingRight: 10, paddingBottom: 10, }}>
                                        <Typography align="left" variant="h6" style={{ paddingLeft: 5 }}>{tutorialSteps[activeStep].label}</Typography>
                                    </Paper>
                                    <img
                                        className={classes.img}
                                        src={tutorialSteps[activeStep].imgPath}
                                        alt={tutorialSteps[activeStep].label}
                                    />
                                </div>
                            </Container>
                        </Paper>
                    </DialogContent>
                    <DialogActions>
                        <Button autoFocus onClick={handleClose} >
                            Close
                        </Button>
                    </DialogActions>
                </Dialog>
            </Container>
        </ClickAwayListener>
    );
}

